import RegionSwitchMobile from './regionSwitchMobile';
import ReceivePaths from './receivePaths';

export default function ProgramDetails(props) {
  const hasSelectedRegion = () => props.program.regionsWithServices.length > 0 && props.showRegion >= 0;

  return (
    <div className="dg-media-list dg-media-list-ways dg-tab-content">
      <div className="tab-pane active">
        <div className="dg-media-region">
          <div className="dg-media-region-info">
            {hasSelectedRegion() &&
              <h4 className="dg-regio-type">Programm {props.program.regionsWithServices[props.showRegion].region}</h4>
            }
            <h5 className="dg-media-heading">
              <span className="dg-search-intro">Empfang für </span>
              <span className="dg-plz-lk">{props.place.districtInfo === null ? '' : props.place.districtInfo + " " + props.place.districtName + " "}</span>
              <span className="dg-plz-info">{props.place.zip}</span>
              <span className="dg-city-first">{props.place.place}</span>
            </h5>
          </div>
          { (props.program.regionsWithServices.length > 0) && <RegionSwitchMobile active={props.regioActive} program={props.program} setShowRegion={props.setShowRegion} showRegion={props.showRegion}/> }
        </div>
        {<ReceivePaths nextProgram={props.nextProgram} program={props.program} services={hasSelectedRegion()
          ? props.program.regionsWithServices[props.showRegion].services
          : props.program.services} selectedServiceId={props.selectedServiceId} setSelectedServiceId={props.setSelectedServiceId}/>}
      </div>
    </div>
  );
}
