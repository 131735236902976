import { useEffect, useState } from "react";
import Tracker from './tracking'

export default function Empfangswege(props) {
  props.setNewLocationSearchActive(false);
  const receiversUrl =
    props.endpoint + "/api/receiver?format=json";

  const [receivers, setReceivers] = useState([]);
  const [show, setShow] = useState("0");

  function sortOrdered(sorter) {
    sorter.sort((a, b) => {
      if (a.sortOrder < b.sortOrder) return -1;
      if (a.sortOrder > b.sortOrder) return 1;
      return 0;
    });
    return sorter;
  }

  useEffect(() => {
    if (receivers.length === 0)
      return;
    const tracker = new Tracker();
    tracker.sendPage({
      page: `brde.service.suche.frequenz-suche.empfangswege.${receivers[show].typeId}.page`,
      inhaltstyp: 'Empfangswege',
      seitenname_id: 'frequenzsuche/empfangswege',
      seitentitel: 'Empfangswege'
    }, [show, receivers])
  })
  useEffect(() => {
    fetch(receiversUrl)
      .then((data) => data.json())
      .then((data) => setReceivers(sortOrdered(data)))
      .catch((e) => {
      });
  }, [receiversUrl]);

  return (
    <div id="dg-outer-wrapper">
      <div id="dg-wrapper" className="dg-wrapper">
        <div className="dg-container">
          <section
            id="serviceTab"
            className="dg-content dg-how-to-services active"
          >
            <ul className="dg-nav nav">
              {receivers.map((r) => {
                return (
                  <li
                    key={r.sortOrder}
                    className={show === r.sortOrder ? "dg-active" : ""}
                  >
                    <a
                      className="dg-media-button"
                      href={r.serviceType}
                      onClick={(e) => {
                        e.preventDefault();
                        setShow(r.sortOrder);
                      }}
                    >
                      {r.serviceType}
                    </a>
                  </li>
                );
              })}
            </ul>
            <ul className="dg-help">
              {receivers.map((r) => {
                return (
                  <li
                    key={r.sortOder}
                    className={show === r.sortOrder ? "active" : ""}
                  >
                    <div className="help-content">
                      <h1>{r.title}</h1>
                      {r.content.column1 && (
                        <p
                          dangerouslySetInnerHTML={{
                            __html: r.content.column1,
                          }}
                        />
                      )}
                      <p>
                        {r.content.column2 && (
                          <>
                            <span
                                dangerouslySetInnerHTML={{
                                  __html: r.content.column2,
                                }}
                            />
                            <br/>
                            <br/>
                          </>
                        )}
                        {r.content.info && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: r.content.info
                            }}
                          />
                        )}
                      </p>
                      {r.content.more && (
                        <p
                          className="dg-more"
                          dangerouslySetInnerHTML={{ __html: r.content.more }}
                        />
                      )}
                    </div>
                    <div className="help-arrow"></div>
                  </li>
                );
              })}
            </ul>
          </section>
        </div>
      </div>
    </div>
  );
}
