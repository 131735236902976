/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from 'react';
import Tracking from './tracking';

export default function RegionSwitchMobile(props) {
    useEffect(() => {
      if (!props.showRegion)
        return;
      const tracker = new Tracking();
      const program = props.program.program;
      tracker.sendPage({
        page: `brde.service.suche.frequenz-suche.${program}.regionswitch`,
        page_chapter2: program,
        welle_angebot: program,
        inhaltstyp: 'Regionswitch',
        seitenname_id: `frequenzsuche/${program}/regionswitch/${props.showRegion}`,
        seitentitel: "Regionswitch"
      }); 
    }, [props.showRegion]);
    return (
        <>
        <div style={{display: props.active?'':'none'}} className="vis-mobile">
            <button className={"dg-regio-free " + (props.showRegion === -1 ? 'dg-active active' : '')}>
                <a href="#" onClick={() => props.setShowRegion(-1)}>Alle</a>
            </button>
            { props.program.regionsWithServices.map((region,index) => {
                return (
                    <button key={index} className={"dg-regio-free" + (props.showRegion === index ? ' dg-active active' : '')}>
                        <a href="#" onClick={() => props.setShowRegion(index)}>{region.region}</a>
                    </button>
                )
            })}
        </div>
        <div style={{clear: 'both'}}/>
        </>
    )
}
