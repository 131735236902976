export default function ProgramLogo(props) {
    let logoMax = props.program.metadata.logo480;
    let logoMin = props.program.metadata.logo128;
    let programName = props.program.program;
    let chosenRegion;
    if (props.active && props.showRegion >=0 && props.program.regionsWithServices && props.program.regionsWithServices.length > 0) {
      chosenRegion = props.program.regionsWithServices[props.showRegion];
      programName += ` für ${chosenRegion.region}`;
      logoMax = chosenRegion.metadata.logo480;
      logoMin = chosenRegion.metadata.logo128;
    }
    return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a className="dg-media-left programmlogo-active" style={{display: props.active?'block':'none'}}>
            <img id="LOGOMAX-10" className="dg-media-object dg-media-big" alt={'Programmlogo ' + programName } src={`img/${logoMax}.png`}/>
            <img id="LOGOMIN-10" className="dg-media-object dg-media-small" alt={'Programmlogo ' + programName } src={`img/${logoMin}.png`}/>
        </a>
    )
}