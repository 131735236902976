const moment = require('moment');

export default class Tracker {
  dateNow = moment().format('YYYYMMDD');
  defaultPage = {
    page: 'brde.service.suche.frequenz-suche.index.page',
    page_chapter1: 'Sonstiges',
    page_chapter2: '',
    page_chapter3: '',
    bereich: 'Sonstiges',
    rubrik: 'Empfangswegauswahl',
    sendereihe: 'keine Sendereihe',
    tag_schlagworter: '|Frequenzsuche|',
    ausliefernde_plattform: 'Web',
    welle_angebot: 'ohne Welle/Angebot',
    inhaltstyp: 'Startseite',
    seitenname_id: 'frequenzsuche/startseite',
    seitentitel: 'Startseite',
    datum: this.dateNow,
    url: window.location.origin,
  }

  sendPage = (values) => {
    //const outEvent = Object.assign({}, this.defaultPage, values);
    //window._pac = window._pac || {};
    //window._paq.push(['sendEvent', 'page.display', outEvent]);
    return;
  }
}
