import { useEffect } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { bindKeyboard } from 'react-swipeable-views-utils';
import ProgramSelection from './programSelection';
import ProgramView from './programView';
import Tracker from './tracking';

const BindKeyboardSwipeableViews = bindKeyboard(SwipeableViews);

export default function ProgramsView(props) {
  useEffect(() => {
    if(props.programAndTypeDeeplinked === true) {
      return;
    }

    props.setProgramAndTypeDeeplinked(true);

    const deepLinkedProgram = new URLSearchParams(window.location.search).get('programm');
    const deepLinkedServiceType = new URLSearchParams(window.location.search).get('type');

    if (deepLinkedProgram?.length > 0) {
      let index = props.locationMetadata.programs.findIndex(program =>
        program.program.toLowerCase() === deepLinkedProgram.toLowerCase());
      if (index > 0) {
        props.setCurrentProgramIndex(index);
      }
    };

    if (deepLinkedServiceType?.length > 0) {
      let index = props.locationMetadata.programs[props.currentProgramIndex].services.findIndex(service =>
        service.serviceType.toLowerCase() === deepLinkedServiceType.toLowerCase());
      if (index >= 0) {
        props.setSelectedServiceId(props.locationMetadata.programs[props.currentProgramIndex].services[index].id);
      }
    };
  // eslint-disable-next-line
  }, []); // Empty array as dependency so that this code is only executed once
  
  const getIndexForId = (services, id) => {
    let index = services.findIndex(service => service.id === id);
    return index === -1 ? 0 : index;
  }

  useEffect(() => {
    if (props.currentProgramIndex === undefined)
      return;
    const program = props.locationMetadata.programs[props.currentProgramIndex].program;
    let serviceIndex = 0;
    if (props.selectedServiceId)
      serviceIndex = getIndexForId(props.locationMetadata.programs[props.currentProgramIndex].services, props.selectedServiceId);
    const service = props.locationMetadata.programs[props.currentProgramIndex].services[serviceIndex];
    const tracker = new Tracker();
    tracker.sendPage({
      page: `brde.service.suche.frequenz-suche.${program}.${service.receivePath}.${service.serviceType}`,
      page_chapter2: program,
      welle_angebot: program,
      inhaltstyp: 'Wellenseite',
      seitenname_id: `frequenzsuche/${program}/${service.receivePath}/${service.serviceType}`,
      seitentitel: service.receivePath
    }); 
  }, [props]);

  return (
    <div>
      <ProgramSelection
        locationMetadata={props.locationMetadata}
        currentProgramIndex={props.currentProgramIndex}
        setCurrentProgramIndex={props.setCurrentProgramIndex}
        nextProgram={props.nextProgram}
        previousProgram={props.previousProgram}
        />
      <div id="dg-outer-wrapper">
        <div id="dg-wrapper" className="dg-wrapper">
          <div className="dg-container">
              <section className="dg-content dg-how-to-start active" id="mainTab">
                <div className="swiper-container" id="dg-swiper-1">
                  <div className="swiper-wrapper">
                    <div className="swiper-slide dg-media swiper-slide-visible swiper-slide-active">
                      <BindKeyboardSwipeableViews enableMouseEvents
                          onChangeIndex={(index) => {props.setCurrentProgramIndex(index);}}
                          index={props.currentProgramIndex}>
                        {props.locationMetadata.programs.map((program,index) => (
                          <ProgramView place={props.place} nextProgram={props.nextProgram} active={(props.currentProgramIndex === index)} key={program.id} program={program} location={props.location} selectedServiceId={props.selectedServiceId} setSelectedServiceId={props.setSelectedServiceId}/>
                        ))}
                      </BindKeyboardSwipeableViews>
                    </div>
                  </div>
                </div>
              </section>
          </div>
        </div>
      </div>
      {/*{ JSON.stringify(props.location) }*/}
    </div>
  );
}
