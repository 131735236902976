export default function ProgramNavigationImage(props) {
  return (
    <button
      key={"pro" + props.index}
      id={"pro" + props.index}
      title={props.item.program}
      className={"z9 swiper-slide"
                + (props.item.metadata.type === 'TV' ? " dg-tv" : " dg-radio")
                + (props.active ? " active" : '')
      }
      onClick={() => props.setCurrentProgramIndex(props.index)}
    >
        <img
            className={props.active ? "dg-color" : "dg-sw"}
            alt={props.item.program}
            src={ props.active ? "img/" + props.item.metadata.logo128 + ".png" : "img/" + props.item.metadata.logo128 + "sw.png" } 
            />
        <span><em>{props.item.program}</em></span>
    </button>
  );
}
