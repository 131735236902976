import { useState, useEffect } from 'react';
import ProgramLogo from './programLogo';
import RegionSwitch from './regionSwitch';
import ProgramDetails from './programDetails';

export default function ProgramView(props) {
  const [showRegion, setShowRegion] = useState();

  useEffect(() => {
    const regioMatch = props.program.regionsWithServices.findIndex(region => region.regionsauswahl && region.regionsauswahl === region.programId);
    regioMatch === undefined ? setShowRegion(-1) : setShowRegion(regioMatch);
  },[props.program]);

  return (
    <>
      <ProgramLogo program={props.program} active={props.active} showRegion={showRegion}/>
      { (props.program.regionsWithServices.length > 0) && <RegionSwitch active={props.active} program={props.program} setShowRegion={setShowRegion} showRegion={showRegion}/> }
      <div className="dg-media-body">
        <h4 className="dg-media-heading">{props.program.program}</h4>
        <ProgramDetails place={props.place} regioActive={props.active} program={props.program} setShowRegion={setShowRegion} showRegion={showRegion} location={props.location} selectedServiceId={props.selectedServiceId} setSelectedServiceId={props.setSelectedServiceId}/>
        <div className="dg-next-link" onClick={props.nextProgram}>nächstes Programm</div>
      </div>
    </>
  );
}
